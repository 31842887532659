import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { IoIosList } from "react-icons/io";
import Loading from "../../components/Loading";
import AddProcessModal from "../../components/AddProcessModel";
import { Button, DatePicker, Input, Pagination } from "antd";
import { LuLayoutGrid } from "react-icons/lu";
import GridProcess from "../../components/CardProcesso/Card";
import ListProcess from "../../components/CardProcesso/List";
import { useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { FiPlus, FiUploadCloud } from "react-icons/fi";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
// import * as yup from "yup";
import locale from "antd/es/date-picker/locale/pt_BR";
import toast from "react-hot-toast";
import {
  useAddProcessFavoriteMutation,
  useDeleteProcessMutation,
  useFindDataProcessMutation,
  useFindProcessMutation,
  useGetProcessDocumentsInfoByIdMutation,
  useGetProcessesPaginationMutation,
  useGetProcessesQuery,
} from "../../store/services/processesApi";
import { useAppSelector } from "../../store/hooks";

dayjs.locale("pt-br");

// const processSchema = yup.object().shape({
//   id: yup.string().required(),
//   process_number: yup.string().required(),
//   process_title: yup.string().required(),
//   process_description: yup.string().required(),
//   labels_primary: yup.string().required(),
// });

function useKey(key, cb) {
  const callback = useRef(cb);

  useEffect(() => {
    callback.current = cb;
  });

  useEffect(() => {
    function handle(event) {
      if (event.code === key) {
        callback.current(event);
      } else if ((event.ctrlKey || event.metaKey) && event.key === "n") {
        callback.current(event);
      }
    }

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [key]);
}

const Dashboard = () => {
  const { sidebarOpen } = useAppSelector((state) => {
    return {
      sidebarOpen: state.sidebarSlice.sidebarOpen,
    };
  });

  const [listOrGrid, setListOrGrid] = useState("Grid");
  const [linkPDFDownload, setLinkPDFDownload] = useState();

  const [isModalOpen, setIsModalOpen] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState("");
  const [pageParent, setPageParent] = useState(0);
  const [processList, setProcessList] = useState();

  const navigate = useNavigate();

  const [handleDownloadProcessPDF] = useGetProcessDocumentsInfoByIdMutation();
  const [
    searchProcess,
    {
      data: processeSearch,
      error,
      isLoading: isLoadingProcessSearch,
      isSuccess,
    },
  ] = useFindProcessMutation();
  const [searchProcessData, { data: processeDataSearch }] =
    useFindDataProcessMutation();
  const [setProcessFavorite] = useAddProcessFavoriteMutation();
  const [deleteProcess] = useDeleteProcessMutation();
  const [getProcessPagination, { isLoading: isLoadingProcessPage }] =
    useGetProcessesPaginationMutation();

  const { processes, count, isFetchingReady } = useGetProcessesQuery(
    undefined,
    {
      selectFromResult: ({ ...result }) => ({
        processes: result?.data?.data ?? [],
        count: result?.data?.count,
        isFetchingReady: result.isFetching,
      }),
    }
  );

  const handleSearchProcess = (value) => {
    setSearchValue(value);
    if (value.length > 0) {
      searchProcess(value);
    }
  };

  const handleSearchData = useCallback(
    (data) => {
      setSearchData(data);
      if (data) {
        setSearchValue("");
        const response = searchProcessData(data);

        response.then((res) => {
          if (Object.keys(res).includes("error")) {
            toast.error(`Nenhum processo encontrado na data ${data}`);
          }
        });
      }
    },
    [searchProcessData]
  );

  const handleDeleteProcess = useCallback(
    (id) => {
      const response = deleteProcess(id);

      response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Erro ao deletar processo");
        } else {
          toast.success("Processo deletado com sucesso!");
        }
      });
    },
    [deleteProcess]
  );

  const processFiltered = useMemo(() => {
    if (
      processes &&
      searchValue.length === 0 &&
      !searchValue &&
      !processList &&
      !searchData &&
      !processeDataSearch
    ) {
      return processes;
    } else if (
      processList &&
      searchValue.length === 0 &&
      !searchValue &&
      !searchData &&
      !processeDataSearch &&
      !processeSearch
    ) {
      return processList;
    } else if (
      searchValue?.length > 0 &&
      processeSearch &&
      processeSearch?.length > 0
    ) {
      return processeSearch;
    } else if (searchData && processeDataSearch) {
      return processeDataSearch;
    } else if (error && error?.status === 404 && searchValue?.length > 0) {
      return [];
    } else {
      return processes;
    }
  }, [
    error,
    processList,
    processeDataSearch,
    processeSearch,
    processes,
    searchData,
    searchValue,
  ]);

  const maxProcessesTitleLength = useMemo(() => {
    return processFiltered?.data && isFetchingReady
      ? processFiltered?.data?.reduce(
          (min, process) =>
            process.process_title?.length > 0 &&
            process.process_title?.length < min
              ? process.process_title.length
              : min,
          42
        )
      : 42;
  }, [isFetchingReady, processFiltered?.data]);

  const downloadPDF = useCallback(
    (id) => {
      const response = handleDownloadProcessPDF(id);

      response.then((response) => {
        if (Object.keys(response).includes("error")) {
          toast.error("Erro ao baixar Documento");
        } else {
          setLinkPDFDownload(
            response?.data?.data?.find((item) => item.storage_url).storage_url
          );
        }
      });
    },
    [handleDownloadProcessPDF]
  );

  useEffect(() => {
    if (linkPDFDownload) {
      const link = document.createElement("a");
      link.href = linkPDFDownload;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLinkPDFDownload(undefined);
    }
  }, [linkPDFDownload, navigate]);

  useKey("ctrln", () => setIsModalOpen("addProcess"));

  const handleScrollProcess = useCallback(
    (page) => {
      setPageParent(page);

      const response = getProcessPagination(page);

      response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Erro ao carregar a base de dados");
        } else {
          setProcessList(res.data.data);
        }
      });
    },
    [getProcessPagination]
  );

  return (
    <div className="w-full flex flex-col relative">
      {isFetchingReady ? (
        <Loading />
      ) : (
        <>
          <div className="w-full flex flex-wrap p-2 md:px-0 xl:px-5">
            <div className="py-4 flex flex-wrap gap-2 w-full justify-between">
              <div className="flex ms-3 md:ms-0 gap-2 items-center font-semibold text-2xl leading-6">
                <span className="flex h-7 p-1 bg-secondary" />
                Processos
              </div>
              <div className=" w-full md:w-auto flex items-center gap-3 justify-between">
                <span className="text-[#5E5E5E]">Visualização:</span>
                <div className="w-full flex gap-3 item-center justify-end">
                  <LuLayoutGrid
                    size={22}
                    color={listOrGrid === "Grid" && "#F6BF87"}
                    className="cursor-pointer"
                    onClick={() => setListOrGrid("Grid")}
                  />
                  <IoIosList
                    size={27}
                    color={listOrGrid === "List" && "#F6BF87"}
                    className="cursor-pointer pb-1"
                    onClick={() => setListOrGrid("List")}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row flex-wrap xl:flex-nowrap justify-between relative gap-2 ">
              <div className="w-full xl:w-4/12 2xl:w-6/12 flex flex-col relative gap-1">
                <span className="font-medium">Pesquisar processo</span>
                <Input
                  placeholder="Pesquisar"
                  size="large"
                  value={searchValue}
                  onChange={(e) => handleSearchProcess(e.target.value)}
                  className="hover:border-purpleLight  focus:border-purpleLight focus-within:border-purpleLight focus:shadow-none"
                  prefix={<IoSearch size={20} color="#667085" />}
                />
              </div>
              <div className="w-full flex-wrap xl:flex-nowrap gap-3 xl:w-8/12  2xl:w-6/12 flex relative items-end">
                <div className="w-full md:w-full xl:w-4/12 flex flex-col gap-1">
                  <span className="font-medium">Data de publicação</span>
                  <DatePicker
                    placeholder="Informe uma data"
                    className="hover:border-purpleLight  focus:border-purpleLight focus-within:border-purpleLight focus:shadow-none"
                    size="large"
                    picker="date"
                    locale={locale}
                    onChange={(_, date) => handleSearchData(date)}
                    format={"DD/MM/YYYY"}
                  />
                </div>
                <div className="w-full xl:w-8/12 flex-wrap md:flex-nowrap flex gap-3">
                  <Button
                    className={`${
                      sidebarOpen ? "md:text-sm" : ""
                    } w-full flex items-center justify-center md:w-6/12 font-semibold text-[#50467A] border-[#6557A4] hover:opacity-70 disabled:text-borderGray disabled:border-borderGray`}
                    size="large"
                    disabled={true}
                    onClick={() => setIsModalOpen("importProcess")}
                  >
                    <FiUploadCloud size={22} />
                    IMPORTAR PROCESSOS
                  </Button>
                  <Button
                    className={`${
                      sidebarOpen ? "md:text-sm" : ""
                    } w-full flex items-center justify-center md:w-6/12 font-semibold text-white bg-[#6557A4] hover:border-none hover:opacity-70`}
                    size="large"
                    onClick={() => setIsModalOpen("addProcess")}
                  >
                    <FiPlus size={22} />
                    ADICIONAR PROCESSOS
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {(processFiltered?.length > 0 && !isLoadingProcessSearch) ||
          (isSuccess && processeSearch && error) ? (
            <div className={`w-full flex flex-wrap py-5`}>
              <div
                className={`w-full flex flex-col justify-between relative md:overflow-auto pb-4`}
              >
                {listOrGrid === "Grid" ? (
                  <div
                    className={`w-full grid grid-flow-row-dense grid-cols-4 ${
                      sidebarOpen
                        ? "md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3"
                        : "xl:grid-cols-3 2xl:grid-cols-4"
                    }  4xl:grid-cols-5 gap-6 justify-center md:justify-between relative px-3`}
                  >
                    {processFiltered?.map((process, index) => (
                      <GridProcess
                        key={index}
                        process={process}
                        setProcessFavorite={setProcessFavorite}
                        deleteProcess={handleDeleteProcess}
                        maxProcessesTitleLength={maxProcessesTitleLength}
                        downloadPDF={downloadPDF}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="w-full gap-6 justify-center relative ">
                    {processFiltered?.map((process, index) => (
                      <ListProcess
                        key={index}
                        process={process}
                        setProcessFavorite={setProcessFavorite}
                        deleteProcess={handleDeleteProcess}
                        maxProcessesTitleLength={maxProcessesTitleLength}
                        downloadPDF={downloadPDF}
                        sidebarOpen={sidebarOpen}
                      />
                    ))}
                  </div>
                )}
                <Pagination
                  current={pageParent}
                  onChange={handleScrollProcess}
                  className="w-full mt-4 md:mt-0 pt-4 md:pb-1"
                  align="center"
                  pageSize={8}
                  showQuickJumper={false}
                  showSizeChanger={false}
                  responsive
                  total={(count / 16).toFixed(0)}
                />
              </div>
            </div>
          ) : isLoadingProcessSearch ||
            isLoadingProcessPage ||
            (!processFiltered && !isSuccess && !processeSearch && !error) ? (
            <Loading />
          ) : (
            <div className="w-full flex items-center justify-center min-h-[70vh]">
              <h3 className="text-base md:text-2xl text-center">
                Nenhum processo encontrado
              </h3>
            </div>
          )}
        </>
      )}
      <AddProcessModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(null)}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default Dashboard;
