import { Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import toast from "react-hot-toast";
import FileUpload from "./uploadFile";
import { useCreateNewProcessMutation } from "../../store/services/processesApi";
import { useGetMyUserQuery } from "../../store/services/userApi";

const AddProcessModal = ({ isOpen, onClose, setIsModalOpen }) => {
  const { data: userData } = useGetMyUserQuery();

  const [handleCreateProcess, { isLoading }] = useCreateNewProcessMutation();

  const [inputText, setInputText] = useState();
  const [outputArray, setOutputArray] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputText(value);

    const result = value
      .split(/[,;\n]+/)
      .map((str) => str.trim())
      .filter(Boolean);
    setOutputArray(result);
  };

  const handleAddProcess = (newProcess) => {
    const response = handleCreateProcess(
      newProcess.map((item) => {
        return {
          firm_id: userData?.firm_id,
          process_number: item,
        };
      })
    );
    response.then((response) => {
      if (Object.keys(response).includes("error")) {
        toast.error("Erro ao criar processo");
      } else {
        setIsModalOpen(null);
        setOutputArray([]);
        setInputText();
        toast.success("Processo criado com sucesso");
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div className="w-full  h-full fixed top-0 left-0 bg-[#0000007f] flex justify-center items-center z-[1000] px-4 md:px-0">
      <div className="bg-white w-[500px]">
        <div className="flex justify-between items-center mb-5 bg-[#6557A4] py-3 px-5">
          <span className="m-0 font-roboto font-semibold text-base text-white">
            {isOpen === "addProcess"
              ? "Adicionar Novo Processo"
              : "Importar Novo Processo"}
          </span>
          <button
            className="bg-none border-none cursor-pointer text-white"
            onClick={onClose}
          >
            <MdClose size={22} />
          </button>
        </div>
        <div className="flex flex-col gap-3 p-5">
          <div className="w-full flex border font-poppins rounded-lg px-4 py-2  border-borderGray bg-graySecondary text-[#5E5E5E]">
            <span className="text-sm">
              Os processos não são armazenados pela Biofy Technologies; eles são
              processados diretamente na nuvem, e apenas o resultado compilado é
              exibido.
            </span>
          </div>
          {isOpen === "addProcess" ? (
            <>
              <span className="text-[#5E5E5E] text-sm">
                Informe abaixo o número de identificação do processo. Caso
                queira inserir vários processos, separe os por vírgula “,” ou
                ";".
              </span>
              <div className="w-full flex flex-col gap-2">
                <span className="text-xs text-[#989898]">
                  Número do processo
                </span>
                <TextArea
                  type="text"
                  name="process_number"
                  size="large"
                  autoSize={{
                    minRows: 4,
                    maxRows: 7,
                  }}
                  placeholder="Insira o número de identificação"
                  value={inputText}
                  onChange={handleChange}
                  required
                />
              </div>
            </>
          ) : (
            <>
              <span className="text-[#5E5E5E] text-sm">
                insira um arquivo no formato csv ou xlsx abaixo:
              </span>
              <FileUpload
                outputArray={outputArray}
                setOutputArray={setOutputArray}
              />
            </>
          )}

          <div className="w-full flex gap-3 mt-3">
            <Button
              className="w-full border-[#BDBDBD] text-[#7E7E7E]"
              onClick={() => {
                setIsModalOpen(null);
                setOutputArray([]);
                setInputText();
              }}
            >
              Cancelar
            </Button>
            <Button
              className="w-full bg-[#6557A4] text-white font-bold disabled:bg-graySecondary disabled:border-[#BDBDBD] disabled:text-[#7E7E7E] disabled:opacity-55"
              onClick={() => handleAddProcess(outputArray)}
              disabled={isLoading || !outputArray}
              loading={isLoading}
            >
              Adicionar Processo
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProcessModal;
