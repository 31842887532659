import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { IoCloseOutline } from "react-icons/io5";
import { Collapse, Input } from "antd";
import { IoMdSend } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import {
  useFacRequestersQuery,
  useSendQuestionRagMutation,
} from "../../store/services/chatApi";
import toast from "react-hot-toast";

export default function Chat({ isSidebarOpen }) {
  const { id } = useParams();
  const container = useRef(null);

  const { data: listLastRequest } = useFacRequestersQuery(id, { skip: !id });
  const [sendQuestionRag, { data: responseRequest, isLoading, status }] =
    useSendQuestionRagMutation();

  const { processData } = useAppSelector((state) => {
    return {
      processData: state.processSlice?.process,
    };
  });

  const [openChat, setChatOpen] = useState(false);
  const [msgChat, setMsgChat] = useState(null);
  const [saveMsgText, setSaveMsgText] = useState(null);
  const [haveNotifications, setHaveNotifications] = useState(false);

  const handleSendQuestionRag = useCallback(
    (id, question) => {
      const response = sendQuestionRag(id, question);

      response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Não foi possível enviar sua mensagem tente mais tarde");
          setSaveMsgText(null);
          setMsgChat(msgChat);
        }
      });
    },
    [msgChat, sendQuestionRag]
  );

  useEffect(() => {
    if (!openChat && responseRequest) {
      setHaveNotifications(true);
    }
    //TODO: Está usando o status apenas no array de dependência para apenas disparar o useEffect quando for feita uma nova requisição
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, [listLastRequest, openChat]);

  return (
    <div
      className={`${isSidebarOpen ? "ms-[323px]" : "ms-[80px]"} ${
        processData?.process_number && isSidebarOpen
          ? "w-full md:w-9/12 xl:w-5/12 2xl:w-2/5  "
          : "w-auto"
      } ${
        openChat ? "xl:w-full md:min-w-[380px]" : ""
      } fixed bottom-0 md:max-w-[850px]`}
      onClick={() => {
        if (!openChat) {
          setChatOpen(true);
          setHaveNotifications(false);
        }
      }}
    >
      <div className="w-full flex px-3 gap-4 text-white items-center  bg-purpleSecondary p-3 rounded-t-2xl relative">
        <img src="/img/svg/logoBiofyB.svg" alt="" className="ms-3" />
        {processData?.process_number ? (
          <span className="w-full text-[10px] font-bold md:text-base ">
            Como posso te ajudar com o processo{" "}
            {processData?.process_number && `(${processData?.process_number})`}{" "}
            ?
          </span>
        ) : (
          <span className="w-full font-bold pe-4 ">Posso te ajudar ?</span>
        )}

        {openChat && (
          <IoCloseOutline
            className="ms-auto cursor-pointer"
            onClick={() => {
              setHaveNotifications(false);
              setChatOpen(false);
            }}
            size={23}
          />
        )}
        {haveNotifications && (
          <span className="w-7 h-7 rounded-full  text-white absolute bg-red-600 text-center flex items-center justify-center top-[-18px] right-2 font-bold">
            1
          </span>
        )}
      </div>
      <div
        className={`w-full relative overflow-auto  bg-[#FAFAFA] p-3 ${
          openChat ? "flex h-[400px]" : "hidden h-auto"
        }  flex-col gap-3`}
      >
        {processData?.process_number && (
          <span className="w-full text-xs md:text-base flex justify-center gap-2">
            <b>N° processo: </b>
            {processData?.process_number}
          </span>
        )}
        <div className="w-full h-full text-xs md:text-base flex flex-col overflow-auto justify-between relative gap-4 py-2 ">
          {listLastRequest && listLastRequest.length > 0 && !isLoading ? (
            <div
              className="w-full flex flex-col gap-4 h-96 overflow-auto py-4 px-2"
              ref={container}
            >
              {listLastRequest?.map((item, index) => (
                <div className="w-full flex" key={index}>
                  <Collapse
                    defaultActiveKey={[listLastRequest.length - 1]}
                    expandIconPosition="end"
                    className="w-full bg-[#F3F3F3]"
                    items={[
                      {
                        key: index,
                        label: item?.question,
                        children: (
                          <div className="w-full flex max-h-40 overflow-auto">
                            <p>{item.answer}</p>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              ))}
            </div>
          ) : saveMsgText && saveMsgText.length > 0 && isLoading ? (
            <div className="w-full flex flex-col overflow-auto my-3 gap-2">
              {saveMsgText?.map((item) => (
                <div className="w-full flex" key={item?.id}>
                  <span className="w-full p-2 border rounded-full bg-[#FFFFFF] text-center">
                    {item.value}
                  </span>
                </div>
              ))}
              <span
                className={`w-full p-2 border rounded-md bg-[#FFF2E5] mb-12
              }`}
              >
                Estamos trabalhando na resposta da sua pergunta, assim que
                estiver pronta te avisaremos!
              </span>
            </div>
          ) : !listLastRequest || listLastRequest?.length === 0 ? (
            <div className="w-full flex flex-col px-3 gap-2 text-[#212121] mt-12">
              <span className="underline">
                “Como posso redigir um contrato de locação que inclua cláusulas
                de rescisão antecipada?”
              </span>
              <span className="underline">
                “Qual seria a melhor estratégia para defender um cliente acusado
                de [crime específico]?”
              </span>
              <span className="underline">
                “Quais são as mudanças recentes na lei de proteção de dados?”
              </span>
            </div>
          ) : null}
          <div className="w-full flex justify-center items-end relative bottom-0 py-2">
            {saveMsgText && saveMsgText.length > 0 ? (
              <button
                className="mx-auto w-fit flex bg-white border text-primary border-primary py-1 px-2 rounded-md font-semibold"
                onClick={() => {
                  setSaveMsgText(null);
                }}
              >
                Realizar nova pergunta
              </button>
            ) : (
              <Input
                size="large"
                className="rounded-full shadow-login"
                placeholder="Digite sua dúvida..."
                onPressEnter={() => {
                  handleSendQuestionRag({
                    id,
                    question: msgChat,
                  });
                  setSaveMsgText((prev) => {
                    if (prev && prev.length > 0) {
                      return prev?.concat({
                        value: msgChat,
                        timestamp: new Date(),
                        id: prev.id + 1,
                      });
                    }
                    return [
                      {
                        value: msgChat,
                        timestamp: new Date(),
                        id: 1,
                      },
                    ];
                  });
                  setMsgChat();
                }}
                disabled={saveMsgText && saveMsgText.length > 0}
                onChange={(e) => setMsgChat(e.target.value)}
                value={msgChat}
                suffix={
                  <div
                    className="bg-[#BCBCBC4D] rounded-full p-2 cursor-pointer"
                    onClick={() => {
                      setSaveMsgText((prev) => {
                        if (prev && prev.length > 0) {
                          return prev?.concat({
                            value: msgChat,
                            timestamp: new Date(),
                            id: prev.id + 1,
                          });
                        }
                        return [
                          {
                            value: msgChat,
                            timestamp: new Date(),
                            id: 1,
                          },
                        ];
                      });
                      setMsgChat();
                      handleSendQuestionRag({
                        id,
                        question: msgChat,
                      });
                    }}
                  >
                    <IoMdSend size={12} color="#989898" />
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
