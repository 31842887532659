import React from "react";

import Login from "../../components/Form/Login";
import { useParams } from "react-router-dom";
import RecoveryPassword from "../../components/Form/RecoveryPassword";
import { Toaster } from "react-hot-toast";
import { RecoveryAccount } from "../../components/recoveryAccount";

const LoginForm = () => {
  const { redirect, resetpassword } = useParams();

  return (
    <div className="w-screen flex relative items-center h-screen overflow-hidden bg-white">
      <Toaster />

      {redirect === "recovery" ? (
        <RecoveryPassword />
      ) : resetpassword === "resetpassword" ? (
        <RecoveryAccount />
      ) : resetpassword === "create-password" ? (
        <RecoveryAccount />
      ) : (
        <Login />
      )}

      <div className="hidden h-full md:w-6/12 xl:flex justify-center">
        <img
          className="w-full h-full object-cover"
          src="/img/imgLoginIpsum.jpg"
          alt="imagem-hemmer-login"
        />
      </div>
      <div className="w-full text-xs text-center flex-col-reverse gap-3 md:gap-0 md:flex-row md:text-start md:text-base  flex absolute bottom-0 text-white bg-[#979797] justify-between items-center p-2">
        © Copyright - Biofy Technologies | Todos os Direitos Reservados 2024
        <div className="mr-10 flex items-center justify-center gap-3">
          <img className="w-20 h-full" src="/img/svg/logo.svg" alt="" />
          <img className="w-20 h-full" src="/img/svg/partner.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
