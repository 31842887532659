import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "antd";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import toast from "react-hot-toast";
import {
  useCreateUserMutation,
  useEditUserMutation,
} from "../../../store/services/userApi";
import CustomInputMask from "../../CustomInputMask";
import { useFindAddressByCepMutation } from "../../../store/services/findCepApi";
import { state } from "../../../data/state";

export default function CreateUser({ setModalOpen, userInfo, setUserInfo }) {
  const [getAddressInfoByCep] = useFindAddressByCepMutation();

  const [createUser, { error, isLoading: isLoadingCreate }] =
    useCreateUserMutation();
  const [editUser, { isLoading: isLoadingEdit }] = useEditUserMutation();

  const schema = z.object({
    id: z.number().optional(),
    full_name: z
      .string({
        required_error: "Nome de usuário é obrigatório",
      })
      .min(1, "Nome de usuário é obrigatório"),
    phone: z.string().optional(),
    addressCode: z.string().optional(),
    street: z.string().optional(),
    number: z.string().optional(),
    neighborhood: z.string().optional(),
    state: z.string().optional(),
    city: z.string().optional(),
    email: z.string().email("Email é obrigatório"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onSubmit",
    values: userInfo,
    resolver: zodResolver(schema),
    // defaultValues: {
    //   id: null,
    //   full_name: "",
    //   email: "",
    //   phone: "",
    //   addressCode: "",
    //   street: "",
    //   state: "",
    //   neighborhood: "",
    //   number: "",
    //   city: "",
    //   photo_url: "",
    // },
  });

  const handleFormSubmit = useCallback(
    (data) => {
      if (userInfo) {
        const response = editUser({
          id: data.id,
          full_name: data.full_name,
          email: data.email,
          phone: data.phone,
          addressCode: data.addressCode,
          street: data.street,
          state: data.state,
          neighborhood: data.neighborhood,
          number: data.number,
          city: data.city,
          photo_url: data.photo_url,
        });

        void response.then((res) => {
          if (Object.keys(res).includes("error")) {
            toast.error("Erro ao atualizar usuário");
            reset();
          } else {
            toast.success("Usuário atualizado com sucesso!");
            setModalOpen(false);
            setUserInfo(undefined);
            reset();
          }
        });
      } else {
        const response = createUser(data);

        void response.then((res) => {
          if (Object.keys(res).includes("error")) {
            if (error?.status === 403) {
              toast.error("Sem permissão para criar usuário");
            }
            return toast.error("Erro ao criar usuário");
          } else {
            toast.success("Usuário criado com sucesso!");
            setModalOpen(false);
            setUserInfo(undefined);
          }
        });
      }
    },
    [
      createUser,
      editUser,
      error?.status,
      reset,
      setModalOpen,
      setUserInfo,
      userInfo,
    ]
  );

  const handleGetAddressInfo = useCallback(
    (cep) => {
      if (cep.length === 9) {
        const response = getAddressInfoByCep(cep);

        response.then((res) => {
          if (Object.keys(res).includes("error")) {
            toast.error("Erro ao buscar informações do cep");
          } else {
            const nameState = state[res?.data?.uf];

            setValue("street", res?.data?.logradouro);
            setValue("city", res?.data?.localidade);
            setValue("neighborhood", res?.data?.bairro);
            setValue("state", nameState);
          }
        });
      }
    },
    [getAddressInfoByCep, setValue]
  );

  useEffect(() => {
    if (!userInfo) {
      reset({
        full_name: undefined,
        password: undefined,
        email: undefined,
        phone: undefined,
        addressCode: undefined,
        street: undefined,
        state: undefined,
        neighborhood: undefined,
        number: undefined,
        city: undefined,
        photo_url: undefined,
      });
    }
  }, [reset, userInfo]);

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="w-full flex flex-col bg-white px-8 py-6 gap-4 font-poppins"
    >
      <span className="text-center md:text-start text-base text-[#5E5E5E]">
        O seu plano possuí <b>30 licenças</b> de usuários ao sistema.
      </span>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="w-full grid grid-flow-row-dense grid-cols-6 gap-5"
      >
        <div className="col-span-6">
          <span className="text-[#5C5C5C] text-sm font-normal">Email</span>
          <Controller
            control={control}
            name="email"
            render={({ field: { name, onChange, value } }) => (
              <Input
                size="large"
                name={name}
                onChange={onChange}
                value={value}
                status={errors.email ? "error" : ""}
                placeholder="Insira o seu Email"
                className={`${
                  errors?.email
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.email && (
            <span className="text-[#fe3535] text-sm">
              {errors?.email.message}
            </span>
          )}
        </div>
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">
            Nome Completo
          </span>
          <Controller
            control={control}
            name="full_name"
            render={({ field }) => (
              <Input
                size="large"
                {...field}
                status={errors.full_name ? "error" : ""}
                placeholder="Insira o seu Nome"
                className={`${
                  errors?.full_name
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.full_name && (
            <span className="text-[#fe3535] text-sm">
              {errors?.full_name.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Telefone</span>
          <Controller
            control={control}
            name="phone"
            render={({ field: { name, onChange, value } }) => (
              <CustomInputMask
                name={name}
                size="large"
                onChange={onChange}
                value={value}
                mask={"(XX) XXXXX-XXXX"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                placeholder="Insira o seu Telefone"
                className={`${
                  errors?.phone ? "border-red-600" : ""
                } w-full rounded-sm hover:border-purpleLight focus:border-purpleLight focus:shadow-none`}
              />
            )}
          />
          {errors?.phone && (
            <span className="text-[#fe3535] text-sm">
              {errors?.phone.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">CEP</span>
          <Controller
            control={control}
            name="addressCode"
            render={({ field: { name, value, onChange } }) => (
              <CustomInputMask
                name={name}
                style={{
                  resize: "none",
                }}
                mask={"_____-___"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleGetAddressInfo(e.target.value);
                }}
                value={value}
                placeholder="Insira o seu Cep"
                className={`${
                  errors?.addressCode
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.addressCode && (
            <span className="text-[#fe3535] text-sm">
              {errors?.addressCode.message}
            </span>
          )}
        </div>
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Rua</span>
          <Controller
            control={control}
            name="street"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                placeholder="Insira o nome da rua"
                status={errors.street ? "error" : ""}
                className={`${
                  errors?.street
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.street && (
            <span className="text-[#fe3535] text-sm">
              {errors?.street.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Numero</span>
          <Controller
            control={control}
            name="number"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                type="number"
                status={errors?.number ? "error" : "success"}
                placeholder="Insira o número da rua"
                className={`${
                  errors?.number
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.number && (
            <span className="text-[#fe3535] text-sm">
              {errors?.number.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Bairro</span>
          <Controller
            control={control}
            name="neighborhood"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.neighborhood ? "error" : ""}
                placeholder="Insira o nome do bairro"
                className={`${
                  errors?.neighborhood
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.neighborhood && (
            <span className="text-[#fe3535] text-sm">
              {errors?.neighborhood.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Estado</span>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.state ? "error" : ""}
                placeholder="Insira o nome do Estado"
                className={`${
                  errors?.state
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.state && (
            <span className="text-[#fe3535] text-sm">
              {errors?.state.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-3">
          <span className="text-[#5C5C5C] text-sm font-normal">Cidade</span>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.city ? "error" : ""}
                placeholder="Insira a cidade"
                className={`${
                  errors?.city
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.city && (
            <span className="text-[#fe3535] text-sm">
              {errors?.city.message}
            </span>
          )}
        </div>
      </form>
      <div className="w-full flex gap-4 mt-3">
        <Button
          className="w-full  flex items-center justify-center font-poppins font-semibold text-[#7E7E7E] border-[#7E7E7E] hover:opacity-70"
          size="large"
          onClick={() => {
            setModalOpen(false);
            setUserInfo(null);
            reset();
          }}
        >
          CANCELAR
        </Button>
        <Button
          className="w-full flex items-center justify-center font-semibold font-poppins text-white bg-[#6557A4] hover:border-[#6557A4] hover:opacity-70"
          size="large"
          loading={isLoadingCreate || isLoadingEdit}
          htmlType="submit"
        >
          {userInfo ? "EDITAR" : "CRIAR"}
        </Button>
      </div>
    </form>
  );
}
