import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { Button, Input } from "antd";
import { MdMailOutline } from "react-icons/md";
import { useSendMailRecoveryPasswordMutation } from "../../../store/services/loginApi";

const schema = z.object({
  email: z.string().email("Email é obrigatório"),
});

export default function RecoveryPassword() {
  const navigate = useNavigate();

  const [recoveryPassword, { isLoading }] =
    useSendMailRecoveryPasswordMutation();

  const [emailSend, setEmailSend] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const handleResetPassWord = useCallback(
    (data) => {
      const result = recoveryPassword(data?.email);

      result.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("email invalido");
        } else {
          setEmailSend(true);
        }
      });
    },
    [recoveryPassword]
  );

  return (
    <div className="w-full xl:w-6/12 flex justify-center ">
      <form
        onSubmit={handleSubmit(handleResetPassWord)}
        className="flex flex-col items-center"
      >
        <h1 className="text-[51px] text-center text-primary font-semibold">
          Bem vindo!
        </h1>

        <div className="gap-3 p-5 flex relative flex-col rounded-2xl md:min-w-[480px] max-w-[480px] w-full mb-32 mt-4 shadow-login">
          <div className="flex items-center">
            <span className="w-2 h-2/4 bg-[#f6bf87] me-4" />
            <h3 className="text-[22px] md:text-[26px] font-semibold font-poppins">
              Esqueci minha senha
            </h3>
          </div>

          {emailSend ? (
            <div className="flex flex-col items-center justify-center gap-5">
              <img
                src="/img/svg/bro.svg"
                alt="imagem email enviado"
                className="h-40"
              />
              <span className="text-center md:text-start text-sm font-normal font-poppins">
                O e-mail de redefinição de senha foi enviado para seu email!
              </span>
              <Button
                className="w-full px-3 py-2 bg-custom-gradient text-white font-semibold hover:border-0 hover:opacity-80 rounded-[4px] shadow-login font-poppins"
                size="large"
                onClick={() => navigate("/")}
                loading={isLoading}
              >
                Tela inicial
              </Button>
            </div>
          ) : (
            <>
              <span>Informe seu email para recuperar sua senha</span>
              <div className="w-full flex flex-col">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <div className="w-full flex flex-col">
                      <Input
                        className="text-[16px] px-3 py-2 rounded-sm bg-[#E8E8E8] hover:border-purpleLight focus:border-purpleLight focus-within:border-purpleLight focus:shadow-none"
                        {...field}
                        type="text"
                        size="large"
                        id="username"
                        placeholder="Email"
                        prefix={
                          <MdMailOutline color="#5e5e5e" className="me-2" />
                        }
                        status={errors?.email ? "error" : ""}
                      />
                    </div>
                  )}
                />
                {errors?.email && (
                  <span className="text-[#fe3535] text-sm mt-1">
                    {errors?.email.message}
                  </span>
                )}
              </div>
              <div className="w-full flex gap-3 mt-2">
                <Button
                  className="w-full border-primary text-primary bg-white hover:text-white hover:bg-purpleSecondary font-semibold rounded font-poppins "
                  size="large"
                  onClick={() => navigate("/")}
                >
                  CANCELAR
                </Button>
                <Button
                  className="w-full px-3 py-2 bg-custom-gradient text-white font-semibold hover:border-0 hover:opacity-80 rounded-[4px] shadow-login font-poppins"
                  htmlType="submit"
                  size="large"
                  loading={isLoading}
                >
                  ENVIAR EMAIL
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
