/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const Header = () => {
  return (
    <div className="bg-primary hidden  text-white min-h-[18px] px-1 md:flex justify-between items-center" />
  );
};

export default Header;
