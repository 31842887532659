import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { loginApi } from "./services/loginApi";
import { processesApi } from "./services/processesApi";
import { userApi } from "./services/userApi";
import storage from "redux-persist/lib/storage";
import loginSlice from "./redux/loginRedux";
import sidebarSlice from "./redux/sidebarRedux";
import processSlice from "./redux/processRedux";
import { chatApi } from "./services/chatApi";
import { cepApi } from "./services/findCepApi";
import { pjeApi } from "./services/pjeApi";
import { authMiddleware } from "../helpers/validateToken";
import { recoveryPasswordApi } from "./services/recoveryPasswordApi";

const persistConfig = {
  key: "root",
  storage,
  // TODO: Nome do createSlice do redux adicionado na lista ele nao limpa o estado
  whitelist: ["loginSlice"],
};

export const rootReducers = combineReducers({
  loginSlice: loginSlice,
  processSlice: processSlice,
  sidebarSlice: sidebarSlice,
  [chatApi.reducerPath]: chatApi.reducer,
  [recoveryPasswordApi.reducerPath]: recoveryPasswordApi.reducer,
  [cepApi.reducerPath]: cepApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [processesApi.reducerPath]: processesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [pjeApi.reducerPath]: pjeApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      loginApi.middleware,
      processesApi.middleware,
      userApi.middleware,
      chatApi.middleware,
      cepApi.middleware,
      pjeApi.middleware,
      recoveryPasswordApi.middleware,
      authMiddleware
    ),
});

setupListeners(store.dispatch);

export default store;
