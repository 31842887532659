import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "../components/Layout";
import { PrivateRoute } from "./privateRouter";
import Dashboard from "../pages/dashboard";
import LoginForm from "../pages/loginForm";
import ProcessDetails from "../pages/processDetails";
import Config from "../pages/config";
import People from "../pages/people";
import { useAppSelector } from "../store/hooks";

const Router = () => {
  const { token } = useAppSelector((state) => state.loginSlice);

  return (
    <div className="max-h-full">
      <Routes>
        <Route
          path="/login/:redirect?"
          element={
            token ? <Navigate to="/dashboard" replace={true} /> : <LoginForm />
          }
        />
        <Route
          path="/:resetpassword?/:id"
          element={token ? <Navigate to="/" replace={true} /> : <LoginForm />}
        />{" "}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route path="/dashboard?" element={<Dashboard />} />
            <Route path="/config/:handle?" element={<Config />} />
            <Route path="/people" element={<People />} />
            <Route path="/process/:id" element={<ProcessDetails />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Route>
        </Route>
        <Route
          path="*"
          element={
            token ? (
              <Navigate to="/dashboard" replace={true} />
            ) : (
              <Navigate to="/" replace={true} />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default Router;
