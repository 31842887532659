import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/x-www-form-urlencoded");
      return headers;
    },
  }),
  tagTypes: ["Login"],
  endpoints: (builder) => ({
    authUser: builder.mutation({
      query: ({ username, password }) => {
        const params = new URLSearchParams();
        params.append("grant_type", "");
        params.append("username", username);
        params.append("password", password);
        params.append("scope", "");
        params.append("client_id", "");
        params.append("client_secret", "");

        return {
          url: "/api/v1/login/access-token",
          method: "POST",
          body: params.toString(),
        };
      },
      invalidatesTags: ["Login"],
    }),
    sendMailRecoveryPassword: builder.mutation({
      query: (email) => {
        return {
          url: `/api/v1/password-recovery/${email}`,
          method: "POST",
        };
      },
      invalidatesTags: ["Login"],
    }),
  }),
});

export const {
  useAuthUserMutation,
  useSendMailRecoveryPasswordMutation,
} = loginApi;
