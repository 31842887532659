/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbUsers } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { Avatar } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { logout } from "../../store/redux/loginRedux";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { processesApi } from "../../store/services/processesApi";
import { AiOutlineHome } from "react-icons/ai";
import { userApi } from "../../store/services/userApi";
import { loginApi } from "../../store/services/loginApi";

const listSelect = [
  {
    id: 0,
    name: "Tela inicial",
    router: "/dashboard",
    secondaryRoute: "/process",
    icon: <AiOutlineHome size={30} />,
  },
  {
    id: 1,
    name: "Gestão de Pessoas",
    router: "/people",
    icon: <TbUsers size={30} />,
  },
  {
    id: 2,
    name: "Configurações",
    router: "/config",
    icon: <FiSettings size={30} />,
  },
];

export default function CustomSideBar({ isSidebarOpen, toggleSidebar }) {
  const { isAuthenticated } = useAppSelector((state) => {
    return {
      isAuthenticated: state.loginSlice.token,
    };
  });

  const location = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(processesApi.util.resetApiState(["Processes"]));
    dispatch(userApi.util.resetApiState(["User"]));
    dispatch(loginApi.util.resetApiState(["Login"]));
  };

  return (
    <header
      className={`h-full bg-primary select-none ${
        isSidebarOpen
          ? "w-svw absolute min-w-[315px] 4xl:w-[400px] px-4 md:w-auto"
          : "w-[80px] 4xl:w-[90px] px-2"
      }   text-white  flex flex-col justify-between md:relative z-20`}
    >
      <div className="flex flex-col gap-3 relative h-full">
        <div className="w-full flex items-center justify-center my-6 ">
          <img
            className="cursor-pointer"
            src="/img/logo.png"
            alt=""
            width={55}
            height={70}
            onClick={() => navigate("/dashboard")}
          />
          <span
            className={`flex md:hidden xl:flex bg-custom-btn-gradient rounded-full h-7 w-7 absolute ${
              isSidebarOpen ? "right-0 md:right-[-30px]" : "right-[-22px]"
            } flex items-center justify-center text-secondary cursor-pointer`}
            onClick={() => toggleSidebar(isSidebarOpen)}
          >
            {isSidebarOpen ? (
              <IoIosArrowBack
                size={20}
                onClick={() => toggleSidebar(isSidebarOpen)}
              />
            ) : (
              <IoIosArrowForward
                size={20}
                onClick={() => toggleSidebar(isSidebarOpen)}
              />
            )}
          </span>
        </div>
        {listSelect.map((item) => (
          <React.Fragment key={item.id}>
            {item.name !== "Configurações" ? (
              <>
                <div
                  className={`w-full flex flex-col justify-between p-3 rounded-e-lg ${
                    location.pathname.includes(item.router) ||
                    location.pathname.includes(item.secondaryRoute) ||
                    (location.pathname === "/" && item.id === 0)
                      ? "bg-custom-btn-gradient"
                      : ""
                  } `}
                >
                  <Link
                    className={`${
                      !isSidebarOpen ? "w-full items-center justify-center" : ""
                    } ${
                      location.pathname.includes(item.router) ||
                      location.pathname.includes(item.secondaryRoute) ||
                      (location.pathname === "/" && item.id === 0)
                        ? "text-secondary"
                        : "text-white"
                    }    decoration-transparent  text-lg font-bold flex items-center gap-2 hover:opacity-70`}
                    to={item.router}
                  >
                    {item.icon}
                    {isSidebarOpen && item.name}
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div
                  className="w-full h-full"
                  onClick={() => toggleSidebar(isSidebarOpen)}
                />
                <div className="mt-auto">
                  <div
                    key={item.id}
                    className={`w-full flex flex-col justify-between p-3 rounded-e-lg  ${
                      location.pathname.includes(item.router) ||
                      location.pathname.includes(item.secondaryRoute)
                        ? "bg-custom-btn-gradient text-secondary"
                        : ""
                    } `}
                  >
                    <Link
                      className={`${
                        !isSidebarOpen
                          ? "w-full items-center justify-center"
                          : ""
                      } ${
                        location.pathname.includes(item.router)
                          ? "text-secondary"
                          : "text-white"
                      }    decoration-transparent  text-lg font-bold flex items-center gap-2 hover:opacity-70`}
                      to={item.router}
                    >
                      {item.icon}
                      {isSidebarOpen && item.name}
                    </Link>
                  </div>
                  {isAuthenticated && (
                    <div className="w-full flex flex-col justify-around mb-3">
                      <hr className="p-2 mt-2" />
                      <div
                        className={`w-full flex gap-2 items-center justify-center mb-auto ${
                          !isSidebarOpen ? "h-24" : "h-12"
                        }`}
                      >
                        <div
                          className={`w-full h-full flex items-center px-3 my-2 ${
                            !isSidebarOpen
                              ? "items-center flex-col justify-between "
                              : "justify-between"
                          }`}
                        >
                          <Avatar
                            className="bg-gray-500"
                            size={35}
                            icon={<FaUserAlt />}
                          />
                          <div
                            className="p-2 flex items-center justify-center cursor-pointer bg-[#260b46] hover:bg-[#0f071a] rounded-full"
                            onClick={handleLogout}
                          >
                            <MdOutlineLogout size={22} color="#D6BBFB" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </header>
  );
}
