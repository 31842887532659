import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header";
import { useState } from "react";
import Chat from "../Chat";
import CustomSidebar from "../CustomSidebar";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setSidebarOpen } from "../../store/redux/sidebarRedux";

//
export default function Layout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    dispatch(setSidebarOpen(!isSidebarOpen));
  };

  return (
    <header className="w-lvw h-lvh flex flex-col bg-[#FFFFFF]">
      <Header />
      <header className="flex h-svh md:h-fullNav">
        <CustomSidebar
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
        />
        <Toaster />
        <header className="w-lvw flex h-lvh p-0 overflow-auto relative md:px-8">
          <Outlet />
        </header>
        {location.pathname.includes("process") && (
          <Chat isSidebarOpen={isSidebarOpen} />
        )}
        {/* <div className="w-full h-12 text-xs text-center flex-col-reverse gap-3 md:gap-0 md:flex-row md:text-start md:text-base  flex absolute bottom-0 text-white bg-[#979797] justify-center items-center">© Copyright - Biofy Technologies | Todos os Direitos Reservados 2024</div> */}
      </header>
    </header>
  );
}

