import { TfiArrowRight } from "react-icons/tfi";
import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";
import { formatDate } from "date-fns";

const ENFORCE_TRUNCATE_TITLE_SIZE = 55;

const processSchema = yup.object().shape({
  id: yup.string().required(),
  process_number: yup.string().required(),
  process_title: yup.string().required(),
  process_description: yup.string().required(),
  labels_primary: yup.string().required(),
});

const GridProcess = ({
  process,
  maxProcessesTitleLength = 55,
  downloadPDF,
  setProcessFavorite,
  deleteProcess,
}) => {
  const [isProcessValid, setIsProcessValid] = useState(false);
  const [processContent, setProcessContent] = useState();

  const parseLabels = ({
    labels_primary,
    labels_secondary,
    nTruncateLabels = 5,
  }) => {
    const primaryLabels = labels_primary.split(",").map((label) => {
      return {
        type: "primary",
        text: label,
      };
    });

    const secondaryLabels = labels_secondary.split(",").map((label) => {
      return {
        type: "secondary",
        text: label,
      };
    });

    const allLabels = [...primaryLabels, ...secondaryLabels];

    const missingLabels = allLabels.length - nTruncateLabels;
    if (missingLabels <= 0 || 5 === -1) {
      return allLabels;
    }

    const missingLabelsObject = {
      type: "truncated_label",
      text: `+${missingLabels}`,
    };

    const truncatedLabels = [
      ...allLabels.slice(0, nTruncateLabels),
      missingLabelsObject,
    ];

    return truncatedLabels;
  };

  const truncateTitle = (title, nTruncateChars = maxProcessesTitleLength) => {
    if (ENFORCE_TRUNCATE_TITLE_SIZE) {
      nTruncateChars = ENFORCE_TRUNCATE_TITLE_SIZE;
    }

    if (title.length <= nTruncateChars) {
      return title;
    }

    return title.slice(0, nTruncateChars) + "...";
  };

  useEffect(() => {
    const validateProcess = async () => {
      const isProcessValid = await processSchema.isValid(process);
      setIsProcessValid(isProcessValid);

      try {
        setProcessContent({
          ...process,
          parsed_labels: parseLabels({
            ...process,
            nTruncateLabels: 5,
          }),
          process_title: truncateTitle(process.process_title),
        });
      } catch (error) {}
    };
    validateProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process]);

  return (
    //TODO Original com as constants
    <>
      {isProcessValid ? (
        <div className="w-full col-span-4 md:col-span-2 xl:col-span-1 flex flex-col gap-3 shadow-login rounded-2xl bg-white justify-between">
          <div
            className={`flex text-white text-xs ${
              processContent?.is_private ? "!bg-[#8B0D0D]" : "!bg-bluePrimary"
            } gap-1  w-full py-3 px-4 rounded-2xl border border-borderGray rounded-b-none`}
          >
            <div className="w-full flex flex-col">
              <strong className=" xl:text-base ">
                {processContent?.process_number}
              </strong>
              {processContent?.last_activity_date ? (
                <span className="font-semibold md:text-sm">
                  {formatDate(processContent?.last_activity_date, "dd/MM/yyyy")}
                </span>
              ) : (
                <span className="font-semibold md:text-sm text-bluePrimary">
                  --/--/----
                </span>
              )}
            </div>
            <div className="h-full flex items-center gap-2 md:gap-4 select-none">
              <span
                onClick={() =>
                  setProcessFavorite({
                    id: processContent?.id,
                  })
                }
              >
                {processContent?.is_favorite ? (
                  <IoIosStar
                    className="text-2xl md:text-3xl cursor-pointer"
                    color="#FFE600"
                  />
                ) : (
                  <IoIosStarOutline className="text-2xl md:text-3xl cursor-pointer" />
                )}
              </span>
              <Popconfirm
                title="Deletar processo"
                description="Você tem certeza que deseja deletar este processo?"
                onConfirm={() => {
                  deleteProcess(processContent?.id);
                }}
                okText="Confirmar"
                okButtonProps={{
                  className: "bg-primary",
                }}
                cancelButtonProps={{
                  className: "hover:border-gray-500 hover:text-gray-500",
                }}
                cancelText="Cancelar"
              >
                <FiTrash2
                  id={`popoverButton-${processContent?.id}`}
                  className="text-xl md:text-3xl cursor-pointer"
                />
              </Popconfirm>
            </div>
          </div>
          <strong className="text-dark text-center text-xs xs:text-base px-2 h-12">
            {processContent?.process_title}
          </strong>
          <div className="relative flex text-xs xs:text-[14px] min-h-[85px]  font-normal leading-4 text-dark truncate-multi px-3 mx-2 text-justify">
            {processContent?.process_description}
          </div>
          <hr />
          <div className="w-full flex gap-3 px-2 py-2 mb-3">
            <Link
              to={process?.summary_url}
              className="w-6/12 flex decoration-transparent"
            >
              <Button
                className="w-full text-[10px] xs:text-xs md:text-xs xl:text-base text-btnPrimary font-semibold px-6 hover:border-borderGray disabled:opacity-85 disabled:text-borderGray"
                size="large"
                disabled={!process?.summary_url}
              >
                BAIXAR RESUMO
                <img src="/img/svg/icons/IconDownload.svg" alt="" />
              </Button>
            </Link>
            <Link className="w-6/12" to={`/process/${processContent?.id}`}>
              <Button
                className="w-full text-[10px] xs:text-xs md:text-xs xl:text-base bg-purpleLight hover:opacity-65 text-white border-none disabled:bg-[#F6BF87] font-semibold"
                size="large"
              >
                VISUALIZAR
                <TfiArrowRight size={18} />
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="w-full col-span-4 md:col-span-2 xl:col-span-1 flex flex-col gap-3 shadow-login rounded-2xl bg-white justify-between opacity-85">
          <div
            className={`flex text-white text-xs !bg-[#aaaaaa] gap-1  w-full py-3 px-4 rounded-2xl border border-borderGray rounded-b-none`}
          >
            <div className="w-full flex flex-col">
              <strong className=" xl:text-base">
                {process?.process_number}
              </strong>
              {process?.last_activity_date ? (
                <span className="font-semibold md:text-sm">
                  {formatDate(process?.last_activity_date, "dd/MM/yyyy")}
                </span>
              ) : (
                <span className="font-semibold md:text-sm text-transparent">
                  --/--/----
                </span>
              )}
            </div>
            <div className="h-full flex items-center gap-2 md:gap-4 select-none">
              <span
                onClick={() =>
                  setProcessFavorite({
                    id: process?.id,
                  })
                }
              >
                {process?.is_favorite ? (
                  <IoIosStar
                    className="text-2xl md:text-3xl cursor-pointer"
                    color="#FFE600"
                  />
                ) : (
                  <IoIosStarOutline className="text-2xl md:text-3xl cursor-pointer" />
                )}
              </span>
              <Popconfirm
                title="Deletar processo"
                description="Você tem certeza que deseja deletar este processo?"
                onConfirm={() => {
                  deleteProcess(process?.id);
                }}
                okText="Confirmar"
                okButtonProps={{
                  className: "bg-primary",
                }}
                cancelButtonProps={{
                  className: "hover:border-gray-500 hover:text-gray-500",
                }}
                cancelText="Cancelar"
              >
                <FiTrash2
                  id={`popoverButton-${process?.id}`}
                  className="text-xl md:text-3xl cursor-pointer"
                />
              </Popconfirm>
            </div>
          </div>
          <div className="w-full  flex min-h-[150px] text-xs xs:text-[14px] font-normal leading-4 text-dark truncate-multi px-3 mx-2 justify-center items-center text-center">
            Aguardando informações do processo
          </div>
          <hr />
          <div className="w-full flex gap-3 md:gap-2 px-4 py-2 mb-3">
            <Button
              className="w-6/12 text-[10px] xs:text-xs md:text-xs xl:text-base font-semibold px-6 hover:border-borderGray"
              onClick={() => downloadPDF(process?.id)}
              disabled
              size="large"
            >
              BAIXAR RESUMO
              <img
                src="/img/svg/icons/IconDownload.svg"
                alt=""
                className="opacity-35"
              />
            </Button>
            <Link className="w-6/12" to={`/process/${processContent?.id}`}>
              <Button
                className="w-full text-[10px] xs:text-xs md:text-xs xl:text-base font-semibold px-6 hover:border-borderGray"
                size="large"
                disabled
              >
                VISUALIZAR
                <TfiArrowRight size={18} />
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default GridProcess;
